@font-face {
  font-family: 'pictello';
  src: url('@{fonts_absolute_path}/pictello.eot?89110119');
  src: url('@{fonts_absolute_path}/pictello.eot?89110119#iefix') format('embedded-opentype'),
       url('@{fonts_absolute_path}/pictello.woff2?89110119') format('woff2'),
       url('@{fonts_absolute_path}/pictello.woff?89110119') format('woff'),
       url('@{fonts_absolute_path}/pictello.ttf?89110119') format('truetype'),
       url('@{fonts_absolute_path}/pictello.svg?89110119#pictello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pictello';
    src: url('../font/pictello.svg?65620170#pictello') format('svg');
  }
}
*/
 
 [class^="picto-"]:before, [class*=" picto-"]:before {
  font-family: "pictello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.picto-rss:before { content: '\e800'; } /* '' */
.picto-rss-alt:before { content: '\e801'; } /* '' */
.picto-search:before { content: '\e802'; } /* '' */
.picto-search-circled:before { content: '\e803'; } /* '' */
.picto-search-1:before { content: '\e804'; } /* '' */
.picto-down-open:before { content: '\e805'; } /* '' */
.picto-left-open:before { content: '\e806'; } /* '' */
.picto-right-open:before { content: '\e807'; } /* '' */
.picto-up-open:before { content: '\e808'; } /* '' */
.picto-down-open-mini:before { content: '\e809'; } /* '' */
.picto-left-open-mini:before { content: '\e80a'; } /* '' */
.picto-right-open-mini:before { content: '\e80b'; } /* '' */
.picto-up-open-mini:before { content: '\e80c'; } /* '' */
.picto-down-open-big:before { content: '\e80d'; } /* '' */
.picto-left-open-big:before { content: '\e80e'; } /* '' */
.picto-right-open-big:before { content: '\e80f'; } /* '' */
.picto-up-open-big:before { content: '\e810'; } /* '' */
.picto-down-open-1:before { content: '\e811'; } /* '' */
.picto-left-open-1:before { content: '\e812'; } /* '' */
.picto-right-open-1:before { content: '\e813'; } /* '' */
.picto-up-open-1:before { content: '\e814'; } /* '' */
.picto-link:before { content: '\e815'; } /* '' */
.picto-chat:before { content: '\e816'; } /* '' */
.picto-calendar:before { content: '\e817'; } /* '' */
.picto-calendar-1:before { content: '\e818'; } /* '' */
.picto-location:before { content: '\e819'; } /* '' */
.picto-location-circled:before { content: '\e81a'; } /* '' */
.picto-phone:before { content: '\e81b'; } /* '' */
.picto-phone-circled:before { content: '\e81c'; } /* '' */
.picto-mobile:before { content: '\e81d'; } /* '' */
.picto-mail:before { content: '\e81e'; } /* '' */
.picto-mail-circled:before { content: '\e81f'; } /* '' */
.picto-home:before { content: '\e820'; } /* '' */
.picto-home-1:before { content: '\e821'; } /* '' */
.picto-comment-1:before { content: '\e822'; } /* '' */
.picto-quote:before { content: '\e823'; } /* '' */
.picto-quote-circled:before { content: '\e824'; } /* '' */
.picto-ok:before { content: '\e825'; } /* '' */
.picto-ok-circled:before { content: '\e826'; } /* '' */
.picto-ok-circled2:before { content: '\e827'; } /* '' */
.picto-cancel:before { content: '\e828'; } /* '' */
.picto-cancel-circled:before { content: '\e829'; } /* '' */
.picto-cancel-circled2:before { content: '\e82a'; } /* '' */
.picto-cancel-squared:before { content: '\e82b'; } /* '' */
.picto-plus-circled:before { content: '\e82c'; } /* '' */
.picto-plus:before { content: '\e82d'; } /* '' */
.picto-print:before { content: '\e82e'; } /* '' */
.picto-help:before { content: '\e82f'; } /* '' */
.picto-spin1:before { content: '\e830'; } /* '' */
.picto-spin2:before { content: '\e831'; } /* '' */
.picto-spin3:before { content: '\e832'; } /* '' */
.picto-tools:before { content: '\e833'; } /* '' */
.picto-spin4:before { content: '\e834'; } /* '' */
.picto-traffic-cone:before { content: '\e835'; } /* '' */
.picto-check:before { content: '\e836'; } /* '' */
.picto-spin5:before { content: '\e838'; } /* '' */
.picto-spin6:before { content: '\e839'; } /* '' */
.picto-instagram-circled:before { content: '\f05e'; } /* '' */
.picto-link-ext:before { content: '\f08e'; } /* '' */
.picto-menu:before { content: '\f0c9'; } /* '' */
.picto-gplus-1:before { content: '\f0d5'; } /* '' */
.picto-mail-alt:before { content: '\f0e0'; } /* '' */
.picto-comment-empty:before { content: '\f0e5'; } /* '' */
.picto-quote-left:before { content: '\f10d'; } /* '' */
.picto-quote-right:before { content: '\f10e'; } /* '' */
.picto-puzzle:before { content: '\f12e'; } /* '' */
.picto-ok-squared:before { content: '\f14a'; } /* '' */
.picto-link-ext-alt:before { content: '\f14c'; } /* '' */
.picto-youtube-squared:before { content: '\f166'; } /* '' */
.picto-youtube:before { content: '\f167'; } /* '' */
.picto-instagram-1:before { content: '\f16d'; } /* '' */
.picto-file-pdf:before { content: '\f1c1'; } /* '' */
.picto-whatsapp:before { content: '\f232'; } /* '' */
.picto-user-circle:before { content: '\f2bd'; } /* '' */
.picto-user-circle-o:before { content: '\f2be'; } /* '' */
.picto-twitter:before { content: '\f309'; } /* '' */
.picto-twitter-circled:before { content: '\f30a'; } /* '' */
.picto-facebook:before { content: '\f30c'; } /* '' */
.picto-facebook-circled:before { content: '\f30d'; } /* '' */
.picto-gplus:before { content: '\f30f'; } /* '' */
.picto-gplus-circled:before { content: '\f310'; } /* '' */
.picto-pinterest:before { content: '\f312'; } /* '' */
.picto-pinterest-circled:before { content: '\f313'; } /* '' */
.picto-linkedin:before { content: '\f318'; } /* '' */
.picto-linkedin-circled:before { content: '\f319'; } /* '' */
.picto-instagram:before { content: '\f32d'; } /* '' */
.picto-comment:before { content: '\f4ac'; } /* '' */
.picto-calendar-2:before { content: '\f4c5'; } /* '' */