//*// BREADCRUMBS //*//

.plone-breadcrumb {
  clear: left;
  background-color: transparent; // @plone-breadcrumb-bg;
  margin-bottom: @plone-line-height-computed;
  margin-top: 0; //-@plone-line-height-computed;
  color: @plone-breadcrumb-color;
  .container {
    width: 100% !important;
    padding: 0 !important;
  }
  a {
    color: @plone-breadcrumb-color;
    word-break: break-all;
  }
  > * > span { //you are here
    display: inline;
    color: @plone-breadcrumb-color;
    padding: 0; //@plone-breadcrumb-padding-vertical 0;
    margin-right: .25em;
    position: static;
    left: 0;
    width: auto; height: auto;
    clip: none;
    font-weight: @plone-font-weight-regular;
    @media (max-width: @plone-screen-sm-min) {
      display: none;
    }
  }
  ol {
    display: inline;
    padding: 0;// @plone-breadcrumb-padding-vertical 0;
    list-style: none;
    margin: 0;
    > li {
      font-weight: @plone-font-weight-regular;
      display: inline;
      line-height: 1.25em; // match ol & span height
      position: relative;
      padding: 0 0 0 0;
      + li:after {
        // content: "";
        // position: absolute;
        // padding: 0 5px;
        // color: @plone-breadcrumb-bg;
        // border-left: 5px solid;
        // border-top: 15px solid transparent;
        // border-bottom: 15px solid transparent;
        // left: -21px;
        // top: -15px;
        // padding: 0 !important;
      }
      + li:before {
        content: "/";
        // position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-color;
        // border-left: 5px solid;
        // border-top: 15px solid transparent;
        // border-bottom: 15px solid transparent;
        // left: -20px;
        // top: -15px;
        // padding: 0 !important;
      }
    }
    > #breadcrumbs-current {
      color: @plone-breadcrumb-active-color;
    }
  }
  /* uncomment to hide breadcrumbs on home
  body.section-front-page & {display: none;} //not breadcrumbs on homepage
  */
}