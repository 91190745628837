/* our custom styles */

/*languages*/
	.languages {
		position: relative;overflow: hidden;width: 170px;background-color:#f1f1f1;
		&-icon {
			position: absolute;right: 8px;top: 7px;z-index: 0;
		}
		select {
			cursor: pointer;position: relative;z-index: 1;background-color: transparent;border: medium none;border-radius: 0;box-shadow: none;display: inline-block;font-size: 1.3em;margin: 0 !important;padding: 0.5em 1.5em 0.5em 0.5em;width: 188px;
		 	&:focus, &:active {
		 		background-color: transparent;border: none !important;box-shadow: none;
		 	}
		 	option {
		 		color: #444;
		 	}
		 }
	}

// Grid 2x2 for TinyMCE
	.grid {
		img {
			width: 100%; height: auto;
			margin-bottom: 1.5rem;
		}
	}

// Nav bar
	.plone-toolbar-left #edit-zone {
		z-index: 1000 !important;
	}